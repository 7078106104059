<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-if="stats">
    <!-- <h3 class="text-lg leading-6 font-medium text-gray-900">
      Last 30 days
    </h3> -->
    <dl
      :class="[
        stats.length >= 5 ? 'md:grid-cols-5' : `md:grid-cols-${stats.length}`,
        `mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x`,
      ]"
    >
      <div v-for="item in stats" :key="item.name" class="px-4 py-5 sm:p-6">
        <dt class="text-base font-normal text-gray-900">
          <span>{{ $t(item.name) }}</span>
          <br />
          <!-- <span class="">comparation between queues</span>
          <br> -->
          <!-- <span>{{ item.elementOne }}</span>
          &
          <span>{{ item.elementTwo }}</span> -->
        </dt>
        <dd class="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div
            class="flex items-baseline text-2xl font-semibold encom_primary_text"
          >
            {{
              item.name.toLowerCase().includes("waiting") ||
              item.name.toLowerCase().includes("duration") ||
              item.name.toLowerCase().includes("drop")
                ? getAverageTime(item.statQueueOne)
                : item.statQueueOne
            }}
            <span v-show="item.name.toLowerCase().includes('coefficient')"
              >%</span
            >
            <span class="ml-2 text-sm font-medium text-gray-500">
              from
              {{
                item.name.toLowerCase().includes("waiting") ||
                item.name.toLowerCase().includes("duration") ||
                item.name.toLowerCase().includes("drop")
                  ? getAverageTime(item.statQueueTwo)
                  : item.statQueueTwo
              }}
              <span v-show="item.name.toLowerCase().includes('coefficient')"
                >%</span
              >
            </span>
          </div>

          <div
            v-if="item.statQueueOne > 0 && item.statQueueOne > 0"
            :class="[
              item.changeType === 'increase' ||
              item.changeType === 'decreaseOposite'
                ? 'bg-green-100 text-green-800'
                : 'bg-red-100 text-red-800',
              'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
            ]"
          >
            <ArrowSmUpIcon
              v-if="item.changeType === 'increase'"
              class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <ArrowSmUpIcon
              v-else-if="item.changeType === 'increaseOposite'"
              class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <ArrowSmDownIcon
              v-else-if="item.changeType === 'decreaseOposite'"
              class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <ArrowSmDownIcon
              v-else-if="item.changeType === 'decrease'"
              class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <span class="sr-only">
              {{
                item.changeType === "increase" ||
                item.changeType === "decreaseOposite"
                  ? "Increased"
                  : "Decreased"
              }}
              by
            </span>
            {{ item.change }}
          </div>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/vue/solid";

// const stats = [
//   {
//     name: "Total Subscribers",
//     statQueueOne: "71,897",
//     statQueueTwo: "70,946",
//     change: "12%",
//     changeType: "increase",
//   },
//   {
//     name: "Avg. Open Rate",
//     statQueueOne: "58.16%",
//     statQueueTwo: "56.14%",
//     change: "2.02%",
//     changeType: "increase",
//   },
//   {
//     name: "Avg. Click Rate",
//     statQueueOne: "24.57%",
//     statQueueTwo: "28.62%",
//     change: "4.05%",
//     changeType: "decrease",
//   },
// ];

export default {
  props: ["stats"],
  components: {
    ArrowSmDownIcon,
    ArrowSmUpIcon,
  },
  setup() {
    return {};
  },
  methods: {
    getAverageTime(avg) {
      if (avg > 0) {
        avg = this.timeFormater(avg);
      }
      return avg;
    },
  },
};
</script>
