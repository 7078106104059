<template>
 <div>
  <PbxHeader
   pbxMenuType="queueDetail"
   :periodSelector="period"
   :parseTimeRange="parseTimeRange"
   :parseDateRange="parseDateRange"
   :selectedQueue="selectedQueue"
   :compareElement="selectedElement"
   :timeSelected="selectedtime"
  />

  <div class="flex flex-col" v-if="comparatorData.length > 0">
   <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-5">
     <div class="shadow overflow-hidden border-b border-gray-200 sm:">
      <CompareQueueTable
       :tablePaginatedData="comparatorData"
       :selectedElement="selectedElement"
       :removeComparison="removeComparison"
       :clearFilters="clearFilters"
       :comparisonInfo="comparisonInfo"
      />
     </div>
    </div>
   </div>
  </div>
  <!-- <div v-if="selectedElement.length < 2" class="pt-20">
      <div class="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 9l4-4 4 4m0 6l-4 4-4-4"
          />
        </svg>
        <h3 class="mt-2 text-lg font-medium text-gray-900">
          {{ $t("pleaseSelectTwoQueuesForComparison") }}
        </h3>
      </div>
    </div> -->
  <div v-if="comparatorData.length < 2 && selectedElement.length >= 2" class="pt-20">
   <div class="text-center">
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="mx-auto h-12 w-12 text-gray-400"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor"
    >
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
     />
    </svg>
    <h3 class="mt-2 text-lg font-medium text-gray-900">
     {{ $t("queueHasNoData") }}
    </h3>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";

import TablePagination from "../../components/TablePagination.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import CompareQueueTable from "../../components/PBX/CompareQueueTable.vue";
import StatsComparator from "../../components/StatsComparator.vue";
import { mapGetters } from "vuex";

const comparisonInfo = [
 { value: "abandoned", name: "abandonedCallsCoefficient", show: false },
 { value: "answered", name: "answeredCallsCoefficient", show: true },
 { value: "answered_00_30", name: "averageAnswered_00_30", show: false },
 { value: "answered_31_60", name: "averageAnswered_31_60", show: false },
 { value: "answered_61_90", name: "averageAnswered_61_90", show: false },
 { value: "answered_90+", name: "averageAnswered_90+", show: false },
 { value: "answered_elsewhere", name: "answered_elsewhere", show: false },
 { value: "calls", name: "numberOfCalls", show: true },
 { value: "dissuaded", name: "dissuadedCallsCoefficient", show: false },
 { value: "duration", name: "averageCallDuration", show: true },
 { value: "tranfered", name: "tranfered", show: false },
 {
  value: "waiting_time_on_abandoned",
  name: "averageDropoutTime",
  show: false,
 },
 {
  value: "waiting_time_on_answer",
  name: "averageWaitingTime",
  show: false,
 },
 {
  value: "waiting_time_on_answer_in_queue",
  name: "averageWaitingTimeQueue",
  show: true,
 },
];

export default {
 props: ["presentationKey"],
 components: {
  PbxHeader,
  CompareQueueTable,
  TablePagination,
  StatsComparator,
 },
 data() {
  return {
   selectedtime: [],
   comparisonInfo,
   search: "",
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   queueData: [],
   textMonth: "",
   buttonSelected: "month",
   comparatorData: [],
   queueDataArr: [],
  };
 },
 methods: {
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  setTimeFromParameter(time) {
   let splitTime = time.split(":");
   this.selectedtime[0] = splitTime[0];
   this.selectedtime[1] = splitTime[1];
  },
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getPbxData(key) {
   let period = null;
   let hostName = undefined;
   let time = [];
   let element = undefined;
   let hostTimeZone = null;
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/presentation/${key}`);
    console.log(
     "file: ExpertStatisticsQueueTableDetail.vue ~ line 240 ~ getPbxData ~ res.data",
     res.data
    );

    console.log(
     "file: ExpertStatisticsQueueTableDetail.vue ~ line 249 ~ getPbxData ~ res.data.parameters",
     res.data.parameters
    );
    period = res.data.parameters.period;
    if (res.data.parameters.time) {
     this.setTimeFromParameter(res.data.parameters.time);
    }
    hostName = res.data.parameters.pbx;
    element = res.data.parameters.elements.split(",");
    hostTimeZone = res.data.parameters.timezone;

    this.selectedElement = element;
    this.period = period;
    this.$store.dispatch("hostName", hostName);
    this.$store.dispatch("hostTimeZone", hostTimeZone);

    return res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    this.$emit("error404", error.response);
    this.errorHandling(error);
   }
  },
  async getQueueDetailsDataObj(key) {
   this.$emit("setLoading", true);

   let dataArr = [];

   let getData = await this.getPbxData(key);

   let event = getData.parameters.elements.split(",");

   for (let index = 0; index < event.length; index++) {
    const elementEvent = event[index];

    let data = getData[elementEvent];

    console.log(
     "file: ExpertStatisticsQueueTableDetail.vue ~ line 336 ~ getQueueDetailsDataObj ~ data",
     data
    );

    let dataKeys = Object.keys(data);

    let arrayOfElements = this.getAllElementsFromKeys(dataKeys);

    console.log(
     "file: ExpertStatisticsQueueTableDetail.vue ~ line 91 ~ getQueueDetailsDataObj ~ arrayOfElements",
     arrayOfElements
    );

    let keysParameters = this.getAllParametersFromKey(dataKeys);

    console.log(
     "file: ExpertStatisticsQueueComparator.vue ~ line 237 ~ getQueueDetailsDataObj ~ this.selectedtime",
     this.selectedtime
    );
    if (this.selectedtime && this.selectedtime.length > 1) {
     dataArr.push(
      this.getQueueDetailsDataObjHourly(
       arrayOfElements,
       dataKeys,
       keysParameters,
       data,
       elementEvent
      )
     );
    } else {
     dataArr.push(
      this.getQueueDetailsDataObjAll(arrayOfElements, dataKeys, keysParameters, data, elementEvent)
     );
    }
   }
   console.log("dataArr", dataArr);
   // this.queueData = dataArr;
   this.queueDataArr = dataArr;
   this.$emit("setLoading", false);

   this.getComparatorObj(dataArr);
  },
  getQueueDetailsDataObjHourly(arrayOfElements, dataKeys, keysParameters, data, elementEvent) {
   let queueTime = undefined;
   let dataArr = [];
   let dataObj = {};
   let elementValue = 0;

   console.log(
    "file: ExpertStatisticsQueueComparator.vue ~ line 289 ~ this.selectedtime",
    this.selectedtime
   );
   let firsQuarterHour =
    ("0" + (parseInt(this.selectedtime[0].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
    this.selectedtime[0].slice(-2);

   let lastQuarterHour =
    ("0" + (parseInt(this.selectedtime[1].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
    this.selectedtime[1].slice(-2);

   for (let index = 0; index < arrayOfElements.length; index++) {
    const element = arrayOfElements[index];
    for (let index = 0; index < keysParameters.length; index++) {
     elementValue = 0;
     const keysParameter = keysParameters[index];
     if (element === "queue") {
      dataObj["element"] = elementEvent;
      dataKeys.forEach((el, index) => {
       let splitEl = el.split(":");
       let queueElement = splitEl[0];
       let queueDataType = splitEl[1];
       let elName = data[`queue:name`];
       let queuePeriod = splitEl[2] ? splitEl[2] : null;
       if (queuePeriod) {
        queueTime = queuePeriod.slice(-4);
       }
       dataObj["name"] = elName;
       if (splitEl.length >= 3) {
        if (element == queueElement) {
         if (queueDataType === keysParameter && queueTime) {
          if (
           parseInt(firsQuarterHour) <= parseInt(queueTime) &&
           parseInt(queueTime) <= parseInt(lastQuarterHour)
          ) {
           if (elementValue >= 0) {
            elementValue = elementValue + parseInt(data[el]);
            dataObj[queueDataType] = elementValue;
           } else {
            dataObj[queueDataType] = 0;
           }
          }
         }
        }
       }
      });
     }
     dataArr = dataObj;
    }
   }

   return dataArr;
  },
  getQueueDetailsDataObjAll(arrayOfElements, dataKeys, keysParameters, data, elementEvent) {
   let dataArr = [];
   let dataObj = {};
   for (let index = 0; index < arrayOfElements.length; index++) {
    dataObj = {};
    const element = arrayOfElements[index];
    if (element === "queue") {
     dataObj["element"] = elementEvent;
     dataKeys.forEach((el, index) => {
      let splitEl = el.split(":");
      let queueElement = splitEl[0];
      let queueDataType = splitEl[1];
      if (element == queueElement) {
       if (splitEl.length == 2) dataObj[queueDataType] = data[el];
      }
     });

     dataArr = dataObj;
    }
   }

   return dataArr;
  },
  // async getQueueDetailsDataObj(event) {
  //   this.isLoading = true;
  //   let dataArr = [];
  //   for (let index = 0; index < event.length; index++) {
  //     const elementEvent = event[index];

  //     let data = await this.getQueueDetails(elementEvent);

  //     let dataObj = {};

  //     let dataKeys = Object.keys(data);

  //     let arrayOfElements = this.getAllElementsFromKeys(dataKeys);

  //     for (let index = 0; index < arrayOfElements.length; index++) {
  //       dataObj = {};
  //       const element = arrayOfElements[index];
  //       if (element === "queue") {
  //         dataObj["element"] = elementEvent;
  //         dataKeys.forEach((el, index) => {
  //           let splitEl = el.split(":");
  //           let queueElement = splitEl[0];
  //           let queueDataType = splitEl[1];
  //           if (element == queueElement) {
  //             if (splitEl.length == 2) dataObj[queueDataType] = data[el];
  //           }
  //         });

  //         dataArr.push(dataObj);
  //       }
  //     }
  //   }
  //   console.log("dataArr", dataArr);
  //   // this.queueData = dataArr;
  //   this.queueDataArr = dataArr;
  //   this.isLoading = false;
  //   this.getComparatorObj(dataArr);
  // },
  getComparatorKeys() {
   let keys = {};
   for (let index = 0; index < this.comparisonInfo.length; index++) {
    const element = this.comparisonInfo[index];
    const value = element.value;
    const name = element.name;
    if (element.show) {
     keys[value] = name;
    }
   }
   // let keys = {
   //   calls: "numberOfCalls",
   //   duration: "averageCallDuration",
   //   waiting_time_on_answer_in_queue: "averageWaitingTimeQueue",
   //   answered: "answeredCallsCoefficient",
   // };
   return keys;
  },
  getChangeIncreaseOrDecrease(value1, value2, type) {
   let value = value2 - value1;
   if (value > 0) {
    if (type === "calls" || type === "answered" || type === "answered_00_30") {
     return "increase";
    } else {
     return "increaseOposite";
    }
   } else {
    if (type !== "calls" && type !== "answered" && type !== "answered_00_30") {
     return "decreaseOposite";
    } else {
     return "decrease";
    }
   }
  },
  getComparatorObj(arr) {
   this.comparatorData = [];
   let comparatorObj = {};
   let comparators = this.getComparatorKeys();
   let keys = Object.keys(comparators);
   if (arr.length > 1) {
    keys.forEach((el, index) => {
     let statOne = undefined;
     let statTwo = undefined;
     if (!el.includes("calls") && (el.includes("duration") || el.includes("waiting"))) {
      statOne = arr[0][String(el)] / arr[0]["calls"];
      statTwo = arr[1][String(el)] / arr[1]["calls"];
     } else if (!el.includes("calls") && !el.includes("duration") && !el.includes("waiting")) {
      statOne = ((arr[0][String(el)] / arr[0]["calls"]) * 100).toFixed(1);
      statTwo = ((arr[1][String(el)] / arr[1]["calls"]) * 100).toFixed(1);
     } else {
      statOne = arr[0][String(el)];
      statTwo = arr[1][String(el)];
     }
     comparatorObj = {};
     comparatorObj["elementOne"] = arr[0]["name"];
     comparatorObj["elementTwo"] = arr[1]["name"];
     comparatorObj["name"] = comparators[String(el)];
     comparatorObj["statQueueOne"] = statOne;
     comparatorObj["statQueueTwo"] = statTwo;
     comparatorObj["change"] = this.getComparativeBetween(statOne, statTwo) + "%";
     comparatorObj["changeType"] = this.getChangeIncreaseOrDecrease(statOne, statTwo, el);
     this.comparatorData.push(comparatorObj);
    });
   }
  },
  getComparativeBetween(value1, value2) {
   let comparativeValue = null;
   comparativeValue = ((Math.abs(value1 - value2) / value1) * 100).toFixed(1);
   return comparativeValue;
  },
  getAllParametersFromKey(keys) {
   let arrayOfElements = [];
   keys.forEach((element, index) => {
    let splitEl = element.split(":");
    arrayOfElements.push(splitEl[1]);
   });
   arrayOfElements = [...new Set(arrayOfElements)];
   return arrayOfElements;
  },
  getAllElementsFromKeys(keys) {
   let elementsArr = [];
   keys.forEach((el, index) => {
    let elSplit = el.split(":");
    let element = elSplit[0];
    if (!elementsArr.includes(element) && element.length > 0) {
     elementsArr.push(element);
    }
   });
   return elementsArr;
  },
  filteredData() {
   return this.queueData.filter((data) => {
    return data.element.toLowerCase().includes(this.search.toLowerCase());
   });
  },
  removeComparison(headerName, idx) {
   for (let index = 0; index < this.comparisonInfo.length; index++) {
    if (this.comparisonInfo[index].name.toLowerCase() === headerName.toLowerCase()) {
     this.comparisonInfo[index].show = !this.comparisonInfo[index].show;
    }
   }
   this.getComparatorObj(this.queueDataArr);
  },
  clearFilters() {
   for (let index = 0; index < this.comparisonInfo.length; index++) {
    this.comparisonInfo[index].show = false;
   }
   this.getComparatorObj(this.queueDataArr);
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time && time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
 },

 mounted() {
  this.getQueueDetailsDataObj(this.presentationKey);
  // this.getButtonSelectedFromQueryPeriod();
 },
 computed: {
  ...mapGetters(["hostName", "hostTimeZone"]),
 },
};
</script>

<style></style>
